"use strict";
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (Object.hasOwnProperty.call(mod, k)) result[k] = mod[k];
    result["default"] = mod;
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const React = __importStar(require("react"));
class SocialIcon extends React.PureComponent {
    render() {
        return (React.createElement(React.Fragment, null,
            React.createElement("a", { className: "list", target: "_blank", href: "https://www.linkedin.com/company/siply-india/" },
                React.createElement("i", { className: "fa fa-linkedin", "aria-hidden": "true" })),
            React.createElement("a", { className: "list", target: "_blank", href: " https://www.youtube.com/channel/UCOwGwh7ZM2mvgWHv3NHWBUA" },
                React.createElement("i", { className: "fa fa-youtube-play", "aria-hidden": "true" }))));
    }
}
exports.SocialIcon = SocialIcon;
