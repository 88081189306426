"use strict";
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (Object.hasOwnProperty.call(mod, k)) result[k] = mod[k];
    result["default"] = mod;
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const React = __importStar(require("react"));
require("../../styles/xoxoday/header.scss");
class Header extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            xoxoday: "/xoxoday",
            chqbook: "/chqbook",
            quess: "/quess",
            acc: "/acc",
            pmwReg: "/pmw",
            gig4ce: "/gig4ce",
            gig4cediwali: "/gig4ce-partner-reward",
            exp1: "/exp1",
            awign: "/awign",
            bighaat: "/bighaat",
            samridh: "/samridh-kendra",
            dp1: "/dp1",
            rewards: "/rewards"
        };
        this.getRegOrDown = () => {
            if (window.location.pathname == this.state.xoxoday) {
                return (React.createElement("div", { className: "part1" },
                    React.createElement("div", { className: "header-logo1" },
                        React.createElement("img", { src: "https://assets.siply.in/website/siply_logo_03.svg" })),
                    React.createElement("div", { className: "line" },
                        React.createElement("hr", { className: "red-line" })),
                    React.createElement("div", { className: "header-logo2" },
                        React.createElement("img", { src: "https://assets.siply.in/xoxo/xoxoday_logo.png" }))));
            }
            else if (window.location.pathname == this.state.chqbook) {
                return (React.createElement("div", { className: "part1" },
                    React.createElement("div", { className: "header-logo1" },
                        React.createElement("img", { src: "https://assets.siply.in/website/siply_logo_03.svg" })),
                    React.createElement("div", { className: "line" },
                        React.createElement("hr", { className: "red-line" })),
                    React.createElement("div", { className: "header-logo2" },
                        React.createElement("img", { src: "https://assets.siply.in/xoxo/chqbook-logo_2x.png" }))));
            }
            else if (window.location.pathname == this.state.quess) {
                return (React.createElement("div", { className: "part1" },
                    React.createElement("div", { className: "header-logo1" },
                        React.createElement("img", { src: "https://assets.siply.in/website/siply_logo_03.svg" })),
                    React.createElement("div", { className: "line" },
                        React.createElement("hr", { className: "red-line" })),
                    React.createElement("div", { className: "header-logo2" },
                        React.createElement("img", { src: "https://assets.siply.in/xoxo/Quess-logo.png" }))));
            }
            else if (window.location.pathname == this.state.acc) {
                return (React.createElement("div", { className: "part1" },
                    React.createElement("div", { className: "header-logo1acc" },
                        React.createElement("img", { src: "https://assets.siply.in/website/siply_logo_03.svg" })),
                    React.createElement("div", { className: "lineacc" },
                        React.createElement("hr", { className: "red-line" })),
                    React.createElement("div", { className: "header-logo2acc" },
                        React.createElement("img", { src: "https://assets.siply.in/xoxo/acc.png" }))));
            }
            else if (window.location.pathname == this.state.pmwReg) {
                return (React.createElement("div", { className: "part1" },
                    React.createElement("div", { className: "header-logo1" },
                        React.createElement("img", { src: "https://assets.siply.in/website/siply_logo_03.svg" })),
                    React.createElement("div", { className: "line" },
                        React.createElement("hr", { className: "red-line" })),
                    React.createElement("div", { className: "header-logo2" },
                        React.createElement("img", { src: "https://assets.siply.in/xoxo/pick-my-work.png" }))));
            }
            else if (window.location.pathname == this.state.gig4ce) {
                return (React.createElement("div", { className: "part1" },
                    React.createElement("div", { className: "header-logo1" },
                        React.createElement("img", { src: "https://assets.siply.in/website/siply_logo_03.svg" })),
                    React.createElement("div", { className: "line" },
                        React.createElement("hr", { className: "red-line" })),
                    React.createElement("div", { className: "header-logo2" },
                        React.createElement("img", { src: "https://assets.siply.in/xoxo/gig4ce.jpg" }))));
            }
            else if (window.location.pathname == this.state.gig4cediwali) {
                return (React.createElement("div", { className: "part1" },
                    React.createElement("div", { className: "header-logo1" },
                        React.createElement("img", { src: "https://assets.siply.in/website/siply_logo_03.svg" })),
                    React.createElement("div", { className: "line" },
                        React.createElement("hr", { className: "red-line" })),
                    React.createElement("div", { className: "header-logo2" },
                        React.createElement("img", { src: "https://assets.siply.in/xoxo/gig4ce.jpg" }))));
            }
            else if (window.location.pathname == this.state.awign) {
                return (React.createElement("div", { className: "part1" },
                    React.createElement("div", { className: "header-logo1" },
                        React.createElement("img", { src: "https://assets.siply.in/website/siply_logo_03.svg" })),
                    React.createElement("div", { className: "line" },
                        React.createElement("hr", { className: "red-line" })),
                    React.createElement("div", { className: "header-logo2" },
                        React.createElement("img", { src: require('./awign.jpg') }))));
            }
            else if (window.location.pathname == this.state.exp1) {
                return (React.createElement("div", { className: "part1" },
                    React.createElement("div", { className: "header-logo1" },
                        React.createElement("img", { src: "https://assets.siply.in/website/siply_logo_03.svg" }))));
            }
            else if (window.location.pathname == this.state.bighaat) {
                return (React.createElement("div", { className: "part1" },
                    React.createElement("div", { className: "header-logo1" },
                        React.createElement("img", { src: "https://assets.siply.in/website/siply_logo_03.svg" })),
                    React.createElement("div", { className: "line" },
                        React.createElement("hr", { className: "red-line" })),
                    React.createElement("div", { className: "header-logo2" },
                        React.createElement("img", { src: "https://assets.siply.in/xoxo/big-haat.png" }))));
            }
            else if (window.location.pathname == this.state.samridh) {
                return (React.createElement("div", { className: "part1" },
                    React.createElement("div", { className: "header-logo1" },
                        React.createElement("img", { src: "https://assets.siply.in/website/siply_logo_03.svg" })),
                    React.createElement("div", { className: "line" },
                        React.createElement("hr", { className: "red-line" })),
                    React.createElement("div", { className: "header-logo2" },
                        React.createElement("img", { src: "https://assets.siply.in/website/samridhkendra.png" }))));
            }
            else if (window.location.pathname == this.state.dp1 || window.location.pathname == this.state.rewards) {
                return (React.createElement("div", { className: "part1" },
                    React.createElement("div", { className: "header-logo1" },
                        React.createElement("img", { src: "https://assets.siply.in/website/siply_logo_03.svg" }))));
            }
            else {
                return (React.createElement("div", null));
            }
        };
        var path = window.location.pathname;
        console.log('path: ', path);
    }
    componentDidUpdate(prevProps) {
    }
    render() {
        return (React.createElement("div", { id: "site-header", className: "header-container" },
            this.getRegOrDown(),
            (window.location.pathname != "/serene-quest") && (React.createElement("hr", { className: "hr-line" }))));
    }
}
exports.default = Header;
