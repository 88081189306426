"use strict";
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (Object.hasOwnProperty.call(mod, k)) result[k] = mod[k];
    result["default"] = mod;
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const React = __importStar(require("react"));
require("../../styles/siplyWeb/header.scss");
class Header extends React.Component {
    componentDidMount() { }
    render() {
        return (React.createElement(React.Fragment, null,
            React.createElement("div", { className: "header-compo-container header-bg" },
                React.createElement("div", { className: "part1" },
                    React.createElement("img", { className: "header-logo", src: "https://assets.siply.in/website/siply_logo_03.svg" })))));
    }
}
exports.default = Header;
