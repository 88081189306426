"use strict";
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (Object.hasOwnProperty.call(mod, k)) result[k] = mod[k];
    result["default"] = mod;
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const React = __importStar(require("react"));
require("../../styles/Siply-WebsiteNew/Footer.scss");
const SocialIcon_1 = require("./SocialIcon");
class Footer extends React.Component {
    render() {
        return (React.createElement("div", { className: "siply-footer-container" },
            React.createElement("div", { className: "body" },
                React.createElement("div", { className: "social-section" },
                    React.createElement("div", { className: "impact-content" },
                        React.createElement("div", { className: "siply-logo" },
                            React.createElement("img", { className: "siply-logo-icon", src: "https://assets.siply.in/website/siply_logo_02.svg" }))),
                    React.createElement("div", { className: "fa-pri-supp-section" },
                        React.createElement("div", null,
                            React.createElement("img", { src: "https://assets.siply.in/website/location.svg" })),
                        React.createElement("div", null,
                            React.createElement("p", { className: "fa-pri-supp" },
                                "Siply Services Private Limited,",
                                React.createElement("br", null),
                                "L372, AVR Heights, 2nd Floor,",
                                React.createElement("br", null),
                                "5th Main Rd, Sector 6, HSR Layout,",
                                React.createElement("br", null),
                                "Bengaluru, Karnataka - 560102"))),
                    React.createElement("div", { className: "fa-pri-supp-section", style: { flexDirection: "column" } },
                        React.createElement("div", { className: "fa-pri-supp" },
                            React.createElement("a", { className: "fa-pri-supp", href: "mailto:contact@siply.in" },
                                React.createElement("img", { src: "https://assets.siply.in/website/mail.svg" }),
                                "contact@siply.in"))),
                    React.createElement("div", { className: "impact-content" },
                        React.createElement("div", { className: "icons" },
                            React.createElement(SocialIcon_1.SocialIcon, null)))),
                React.createElement("div", { className: "social-section-mobile" },
                    React.createElement("div", { className: "impact-content" },
                        React.createElement("div", { className: "siply-logo" },
                            React.createElement("img", { className: "siply-logo-icon", src: "https://assets.siply.in/website/siply_logo_02.svg" }))),
                    React.createElement("div", { className: "impact-content" },
                        React.createElement("div", { className: "icons" },
                            React.createElement(SocialIcon_1.SocialIcon, null))),
                    React.createElement("div", { className: "fa-pri-supp-section" },
                        React.createElement("div", null,
                            React.createElement("img", { src: "https://assets.siply.in/website/location.svg" })),
                        React.createElement("div", null,
                            React.createElement("p", { className: "fa-pri-supp" },
                                "Siply Services Private Limited,",
                                React.createElement("br", null),
                                "L372, AVR Heights, 2nd Floor, 5th Main Rd, Sector 6, HSR Layout, Bengaluru, Karnataka - 560102"))),
                    React.createElement("div", { className: "fa-pri-supp-section", style: { flexDirection: "column" } },
                        React.createElement("div", { className: "fa-pri-supp2" },
                            React.createElement("a", { className: "fa-pri-supp2", href: "mailto:contact@siply.in" },
                                React.createElement("img", { src: "https://assets.siply.in/website/mail.svg" }),
                                "contact@siply.in")))),
                React.createElement("hr", { className: "hr-line" }),
                React.createElement("div", { className: "footer-disclaimer" },
                    React.createElement("h4", null,
                        "Siply Services Private Limited (Brand Name - Siply, Siply.in) makes no warranties or representations, express or implied, on products and services offered through the platform.",
                        React.createElement("br", null),
                        " It accepts no liability for any damages or losses, however, caused in connection with the use of, or on the reliance of its advisory or related services."),
                    React.createElement("h4", null,
                        "Past performance is not indicative of future returns. Please consider your specific investment requirements, risk tolerance, goal, time frame, risk and reward balance and the",
                        React.createElement("br", null),
                        " cost associated with the investment before choosing a fund, or designing a portfolio that suits you need. Performance and returns of any investment portfolio can neither be",
                        React.createElement("br", null),
                        " predicted not guaranteed."),
                    React.createElement("h4", null, "Mutual Fund investments are subject to market risks, read all scheme related documents carefully."),
                    React.createElement("h4", null, "Siply and Siply.in are brand and product of Siply Services Private Limited."),
                    React.createElement("hr", { className: "hr-line" }),
                    React.createElement("h4", null, "Copyright \u00A9 2020-21 Siply Services Private Limited, All rights reserved."),
                    React.createElement("h4", null, "[CIN-U74999K2020PTC136118] [\u200BARN-171848\u200B]"),
                    React.createElement("h4", { className: "footer-termsnconditions" },
                        React.createElement("span", null,
                            React.createElement("a", { href: "https://assets.siply.in/website/Siply_Privacy_Policy2.pdf", target: "_blank" }, "Privacy Policy")),
                        React.createElement("span", null,
                            React.createElement("a", { href: "https://assets.siply.in/Siply_T&Cs.pdf", target: "_blank" }, "Terms and Conditions")),
                        React.createElement("span", null,
                            React.createElement("a", { href: "https://assets.siply.in/Siply_Refund_Policy.pdf", target: "_blank" }, "Refund Policy")))))));
    }
}
exports.default = Footer;
