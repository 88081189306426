"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const react_router_dom_1 = require("react-router-dom");
const index_1 = require("../../Routers/index");
class NotFound extends react_router_dom_1.Route {
    componentDidMount() {
        index_1.history.push('/');
    }
}
exports.default = NotFound;
