"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const react_redux_1 = require("react-redux");
const actions_1 = require("../../modules/auth/actions");
const mapStateToProps = (state, props) => {
    return {
        captureFailedMessage: state.login.captureFailedMessage,
        captureSucceededMessage: state.login.captureSucceededMessage,
        isOpen: state.login.IsOpen,
        isUserReg: state.login.isUserReg,
        verifyInfo: state.login.verifyInfo,
        verifyLoader: state.login.verifyLoader,
        submitLoader: state.login.submitLoader
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        captureLead: (name, mn, email, company, reason, message) => dispatch(actions_1.captureLead(name, mn, email, company, reason, message)),
        openDialog: () => dispatch(actions_1.openDialog()),
        // getGold: (name: string, mobileNumber: string, code: string)=> dispatch(getGold(name, mobileNumber, code))
        getGold: (payload) => dispatch(actions_1.getGold(payload)),
        varifyNumber: (payload) => dispatch(actions_1.varifyNumber(payload))
    };
};
exports.AuthModalContainer = react_redux_1.connect(mapStateToProps, mapDispatchToProps);
