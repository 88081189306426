"use strict";
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (Object.hasOwnProperty.call(mod, k)) result[k] = mod[k];
    result["default"] = mod;
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const React = __importStar(require("react"));
require("../../styles/Siply-WebsiteNew/Dialog.scss");
const Auth_1 = require("../../containers/Auth/Auth");
class Dialog extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            mobileNumber: '',
            email: '',
            reason: '',
            company: '',
            message: '',
            name: '',
            title: null,
            enableSubmit: false,
            validEmail: false,
            validPhone: false,
            validCompany: false,
            validReason: false,
            validMessage: false,
            validName: false
        };
        this.handleSubmit = (ev) => {
            ev.preventDefault();
            console.log("handleSubmit", this.props);
            const { mobileNumber, reason, company, name, email, message } = this.state;
            const { captureLead } = this.props;
            captureLead(name, mobileNumber, email, company, reason, message);
        };
        this.handleInputChange = (ev, key) => {
            console.log("handlecode", ev);
            ev.preventDefault();
            console.log("select event", ev.target, ev.target.value);
            const { validPhone, validEmail, validCompany, validReason, validMessage, validName } = this.state;
            this.setState({ enableSubmit: validName && validEmail && validPhone && validCompany && validReason && validMessage, title: null });
            if (key == "name") {
                this.setState({ validName: false });
                if (ev.target.value.length > 2) {
                    this.setState({ validName: true });
                }
                this.setState({ name: ev.target.value });
            }
            else if (key == "company") {
                this.setState({ validCompany: false });
                if (ev.target.value.length > 2) {
                    this.setState({ validCompany: true });
                }
                this.setState({ company: ev.target.value });
            }
            else if (key == "reason") {
                this.setState({ validReason: false });
                if (ev.target.value.length > 0) {
                    this.setState({ validReason: true });
                }
                this.setState({ reason: ev.target.value });
            }
            else if (key == "email") {
                this.setState({ validEmail: false });
                if (ev.target.value.length > 6) {
                    this.setState({ validEmail: true });
                }
                this.setState({ email: ev.target.value });
            }
            else if (key == "mobileNumber") {
                this.setState({ validPhone: false });
                if (ev.target.value.length == 10) {
                    this.setState({ validPhone: true });
                }
                this.setState({ mobileNumber: ev.target.value });
            }
            else if (key == "message") {
                this.setState({ validMessage: false });
                if (ev.target.value.length > 2) {
                    this.setState({ validMessage: true });
                }
                this.setState({ message: ev.target.value });
            }
        };
        this.hideModal = () => {
            const { openDialog } = this.props;
            openDialog();
        };
    }
    componentDidUpdate(prevProps) {
        console.log("componentDidUpdate", prevProps, this.props);
        if (prevProps.captureSucceededMessage != this.props.captureSucceededMessage) {
            this.setState({
                done: true,
                mobileNumber: '',
                email: '',
                reason: '',
                company: '',
                message: '',
                name: '',
                title: this.props.captureSucceededMessage,
                enableSubmit: false,
                validEmail: false,
                validPhone: false,
                validCompany: false,
                validReason: false,
                validMessage: false,
                validName: false
            });
        }
    }
    render() {
        // console.log("PROPSnnnnnnnnn", this.state);
        const { mobileNumber, reason, company, name, email, message, title, enableSubmit } = this.state;
        if (this.props.isOpen) {
            return (React.createElement("div", { className: "dialog-modal" },
                React.createElement("div", { className: "dialogStyles" },
                    React.createElement("div", { className: "modal-cont" },
                        React.createElement("div", { className: "imagePOP" },
                            React.createElement("img", { className: "image-popup", src: "https://assets.siply.in/website/popup_bg.jpg" })),
                        React.createElement("div", { className: "main-modal" },
                            React.createElement("div", { className: "closeButton" },
                                React.createElement("img", { src: "https://books.capitalquotient.com/capitalquotient-website/HomeAssets/close_icon_02.svg", alt: "Close Icon", className: "dialogCloseButtonStyles", 
                                    // onClick={(e) => this.setState({ isOpen: false })}
                                    onClick: this.hideModal })),
                            React.createElement("div", { className: "header-cont" },
                                React.createElement("h1", { className: "header" }, "Happy to help!"),
                                React.createElement("h2", null,
                                    "Please submit your query ",
                                    React.createElement("br", null),
                                    "and we will get in touch with you at the earliest.")),
                            React.createElement("div", { className: "form-content" },
                                React.createElement("div", { className: "form-field" },
                                    React.createElement("input", { className: "inputBox", type: "text", name: "name", placeholder: "Name", value: name, onChange: ev => this.handleInputChange(ev, "name") })),
                                React.createElement("div", { className: "form-field" },
                                    React.createElement("input", { className: "inputBox", type: "text", name: "company", placeholder: "Company", onChange: ev => this.handleInputChange(ev, "company"), value: company })),
                                React.createElement("div", { className: "form-field" },
                                    React.createElement("input", { className: "inputBox", type: "text", name: "business_mail", placeholder: "Business Email", onChange: ev => this.handleInputChange(ev, "email"), value: email })),
                                React.createElement("div", { className: "form-field" },
                                    React.createElement("input", { className: "inputBox", type: "text", name: "phone", placeholder: "Phone", onChange: ev => this.handleInputChange(ev, "mobileNumber"), value: mobileNumber })),
                                React.createElement("div", { className: "form-field" },
                                    React.createElement("select", { className: "dropDown", value: reason, onChange: ev => this.handleInputChange(ev, "reason") },
                                        React.createElement("option", { value: "" }, "Select..."),
                                        React.createElement("option", { value: "Partneship" }, "Partnership"),
                                        React.createElement("option", { value: "Feedback" }, "Feedback/ review"),
                                        React.createElement("option", { value: "Service" }, "Service request"),
                                        React.createElement("option", { value: "Media" }, "Media"),
                                        React.createElement("option", { value: "Demo Request" }, "Demo Request"),
                                        React.createElement("option", { value: "New account" }, "New account creation"),
                                        React.createElement("option", { value: "Others" }, "Others"))),
                                React.createElement("div", { className: "form-field" },
                                    React.createElement("input", { className: "inputBox", type: "text", name: "message", placeholder: "Message", onChange: ev => this.handleInputChange(ev, "message"), value: message })),
                                title &&
                                    React.createElement("div", { className: "form-field submit-message" }, title),
                                React.createElement("div", { className: "button-sub" },
                                    React.createElement("button", { disabled: !enableSubmit, className: !enableSubmit ? "button-submit disabled-btn" : "button-submit", onClick: this.handleSubmit.bind(this) }, "SUBMIT"),
                                    React.createElement("button", { className: "button-cancel", onClick: this.hideModal }, "CANCEL"))))))));
        }
        else {
            return (React.createElement("div", null));
        }
    }
}
exports.default = Auth_1.AuthModalContainer(Dialog);
