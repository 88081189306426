"use strict";
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (Object.hasOwnProperty.call(mod, k)) result[k] = mod[k];
    result["default"] = mod;
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const types = __importStar(require("./types"));
const initialState = {
    captureFailedMessage: "",
    captureSucceededMessage: "",
    IsOpen: false,
    isUserReg: false,
    verifyInfo: "",
    verifyLoader: false,
    submitLoader: false,
};
// const initialDialogState: types.DialogState = {
//     IsOpen : false
// };
function loginReducer(state = initialState, action) {
    switch (action.type) {
        case types.LEADCAPTURE_SUCCEEDED:
            return Object.assign({}, state, { captureSucceededMessage: "Thank you for showing interest. We will get in touch with you at the earliest.", captureFailedMessage: "" });
        case types.LEADCAPTURE_FAILED:
            return Object.assign({}, state, { captureSucceededMessage: "", captureFailedMessage: "You have already submitted. We will get in touch with you at the earliest." });
        case types.DIALOG_OPEN:
            return Object.assign({}, state, { IsOpen: !state.IsOpen });
        case types.FETCH_GOLD_DATA:
            return Object.assign({}, state, { isUserReg: !state.isUserReg });
        case types.FETCH_VARIFY_DATA:
            return Object.assign({}, state, { verifyInfo: action.verifyInfo, verifyLoader: false });
        case types.REQUREST_VARIFY_DATA:
            return Object.assign({}, state, { verifyLoader: true });
        case types.SUBMIT_VARIFY_DATA:
            return Object.assign({}, state, { submitLoader: true });
        case types.SUCCESS_VARIFY_DATA:
            return Object.assign({}, state, { verifyLoader: false });
        case types.SUCCESS_SUBMIT_DATA:
            return Object.assign({}, state, { submitLoader: false });
        default:
            return state;
    }
}
exports.default = loginReducer;
// export function dialogReducer(
//   state = initialDialogState,
//   action: types.DialogAction
// ) {
//   switch (action.type) {
//     case types.DIALOG_OPEN:
//       return {
//         ...state,
//         IsOpen : !state.IsOpen
//       };
//   }
// }
