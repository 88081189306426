"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.LEADCAPTURE_REQUESTED = "LEADCAPTURE_REQUESTED";
exports.LEADCAPTURE_SUCCEEDED = "LEADCAPTURE_SUCCEEDED";
exports.LEADCAPTURE_FAILED = "LEADCAPTURE_FAILED";
exports.HANDLE_LEADCAPTURE_MODAL = "HANDLE_LEADCAPTURE_MODAL";
exports.DIALOG_OPEN = "DIALOG_OPEN";
exports.FETCH_GOLD_DATA = "FETCH_GOLD_DATA";
exports.FETCH_VARIFY_DATA = "FETCH_VARIFY_DATA";
exports.REQUREST_VARIFY_DATA = "REQUREST_VARIFY_DATA";
exports.SUCCESS_VARIFY_DATA = "SUCCESS_VARIFY_DATA";
exports.SUBMIT_VARIFY_DATA = "SUBMIT_VARIFY_DATA";
exports.SUCCESS_SUBMIT_DATA = "SUCCESS_SUBMIT_DATA";
// export type DialogAction =
// | IsOpen
