"use strict";
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (Object.hasOwnProperty.call(mod, k)) result[k] = mod[k];
    result["default"] = mod;
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const React = __importStar(require("react"));
require("../../styles/xoxoday/footer.scss");
class Footer extends React.Component {
    render() {
        return (React.createElement("div", { className: "footer-container" },
            React.createElement("div", { className: "body" },
                React.createElement("hr", { className: "hr-line" }),
                React.createElement("h4", null, "Copyright \u00A9 2020-21 Siply Services Private Limited, All rights reserved."))));
    }
}
exports.default = Footer;
