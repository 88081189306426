"use strict";
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (Object.hasOwnProperty.call(mod, k)) result[k] = mod[k];
    result["default"] = mod;
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const React = __importStar(require("react"));
require("../../styles/Siply-WebsiteNew/SiplyAdvantage.scss");
class SipAdvantage extends React.Component {
    render() {
        return (React.createElement("div", { className: "siply-advantage-container" },
            React.createElement("div", { className: "siply-advantage-header" },
                React.createElement("div", { className: "adv-header2" },
                    "The ",
                    React.createElement("span", null,
                        React.createElement("img", { className: "headerImage", src: "https://assets.siply.in/website/siply_logo_03.svg" })),
                    " Advantage")),
            React.createElement("div", { className: "body" },
                React.createElement("div", { className: "image-desktop" },
                    React.createElement("img", { className: "advImage", src: "https://assets.siply.in/website/advantage_02.jpg" })),
                React.createElement("div", { className: "image-mobile" },
                    React.createElement("div", { className: "image-data" },
                        React.createElement("h2", null,
                            React.createElement("span", { className: "dot" }),
                            "Vernacular approach"),
                        React.createElement("h2", null,
                            React.createElement("span", { className: "dot" }),
                            "Digital onboarding"),
                        React.createElement("h2", null,
                            React.createElement("span", { className: "dot" }),
                            "Instant withdrawal"),
                        React.createElement("h2", null,
                            React.createElement("span", { className: "dot" }),
                            "Goal based")),
                    React.createElement("div", null,
                        React.createElement("img", { className: "advImage2", src: "https://assets.siply.in/website/advantage_mobile_03.jpg" })),
                    React.createElement("div", { className: "image-data" },
                        React.createElement("h2", null,
                            React.createElement("span", { className: "dot" }),
                            "Micro savings starting at Re 1"),
                        React.createElement("h2", null,
                            React.createElement("span", { className: "dot" }),
                            "Wide range of investments"),
                        React.createElement("h2", null,
                            React.createElement("span", { className: "dot" }),
                            "Literacy driven"),
                        React.createElement("h2", null,
                            React.createElement("span", { className: "dot" }),
                            "Pause or restart saving at will"))))));
    }
}
exports.default = SipAdvantage;
