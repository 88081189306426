"use strict";
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (Object.hasOwnProperty.call(mod, k)) result[k] = mod[k];
    result["default"] = mod;
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const React = __importStar(require("react"));
require("../../styles/Siply-WebsiteNew/Header.scss");
class Header extends React.Component {
    constructor(props) {
        super(props);
        this.myRef = React.createRef();
        this.handleClickOutside = (e) => {
            if (!this.myRef.current.contains(e.target)) {
                this.setState({});
            }
        };
        this.fixedHeadreScroll = () => {
            var doc = document.documentElement;
            var w = window;
            var prevScroll = w.scrollY || doc.scrollTop;
            var curScroll;
            var direction = 0;
            var prevDirection = 0;
            var header = document.getElementById("site-header");
            var logo = document.getElementById("logo");
            var logo2 = document.getElementById("logo2");
            let checkScroll = function () {
                curScroll = w.scrollY || doc.scrollTop;
                if (curScroll == prevScroll && curScroll > 0) {
                    direction = 2;
                }
                if (curScroll > prevScroll) {
                    direction = 2;
                }
                else if (curScroll < prevScroll && curScroll !== 0) {
                    direction = 1;
                }
                else if (curScroll === 0) {
                    direction = 0;
                }
                if (direction !== prevDirection) {
                    toggleHeader(direction, curScroll);
                }
                prevScroll = curScroll;
            };
            let toggleHeader = function (direction, curScroll) {
                if (direction === 0 && curScroll === 0) {
                    header.classList.remove("hide");
                    logo2.classList.remove("header-logo2");
                    logo.classList.add("header-logo1");
                    header.classList.remove("active-bg");
                }
                if (direction === 2 && curScroll > 50) {
                    header.classList.remove("hide");
                    logo.classList.remove("header-logo1");
                    logo2.classList.add("header-logo2");
                    header.classList.add("active-bg");
                    prevDirection = direction;
                }
                else if (direction === 1) {
                    header.classList.remove("hide");
                    logo.classList.remove("header-logo1");
                    logo2.classList.add("header-logo2");
                    header.classList.add("active-bg");
                    prevDirection = direction;
                }
            };
            window.addEventListener("scroll", checkScroll);
        };
    }
    componentWillUnmount() {
        document.removeEventListener("mousedown", this.handleClickOutside);
    }
    componentDidUpdate() {
        document.addEventListener("mousedown", this.handleClickOutside);
        this.fixedHeadreScroll();
    }
    componentDidMount() {
        var logo2 = document.getElementById("logo2");
        logo2.classList.remove("header-logo2");
        document.addEventListener("mousedown", this.handleClickOutside);
        this.fixedHeadreScroll();
    }
    render() {
        return (React.createElement("div", { ref: this.myRef, id: "site-header", className: "header-compo-container" },
            React.createElement("div", { className: "part1" },
                React.createElement("div", { id: "logo", className: "header-logo1" },
                    React.createElement("img", { src: "https://assets.siply.in/website/siply_logo_02.svg" })),
                React.createElement("div", { id: "logo2", className: "header-logo2" },
                    React.createElement("img", { src: "https://assets.siply.in/website/siply_logo_03.svg" })))));
    }
}
exports.default = Header;
