"use strict";
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (Object.hasOwnProperty.call(mod, k)) result[k] = mod[k];
    result["default"] = mod;
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const React = __importStar(require("react"));
const react_toastify_1 = require("react-toastify");
require("./notifier.scss");
const toast_config = {
    draggable: true,
    hideProgressBar: true,
    newestOnTop: false
};
const NotificationTemplate = (props) => {
    const { title, message, type } = props;
    return (React.createElement("div", { className: "ath-notifier" },
        React.createElement("div", { className: "ath-notifier__type" },
            React.createElement("img", { src: type === 'success'
                    ? require('./success-icon.svg')
                    : type === 'error'
                        ? require('./error-icon.svg')
                        : type === 'warning'
                            ? require('./warning-icon.svg')
                            : require('./info-icon.svg'), alt: "notify" })),
        React.createElement("div", { className: "ath-notifier__content" },
            React.createElement("h5", { className: `ath-notifier__title-${type}` }, title),
            message && React.createElement("p", { className: "ath-notifier__sub" }, message.length > 200 ? message.slice(0, 200) + "..." : message))));
};
const notifier = (title, message, type) => {
    react_toastify_1.toast(React.createElement(NotificationTemplate, { type: type, title: title, message: message }), Object.assign({}, toast_config, { type: type }));
};
exports.notify = ({
    success: (title, message) => notifier(title, message, "success"),
    error: (title, message) => notifier(title, message, "error"),
    warning: (title, message) => notifier(title, message, "warning"),
    info: (title, message) => notifier(title, message, "info")
});
